/*

GLOBAL SCRIPTS	
	
These functions make sure WordPress
and Foundation play nice together.
*/
//(function($){})(jQuery);

var window = jQuery(window);
jQuery(document).ready(function($) {	
	//initDebugin();
	initJsGlobal();
	initFpStyles();
	initNavigation();
	initGalleries();
	initReplaceFancybox();		
	clickToExpand();
	//concernsImageMapping();
	//initBrowserExposure();
	if(jQuery('#off-canvas').length){
		initOffCanvasSwitcher();	
	}
	
	jQuery(document).scroll(animateFp);

});

var Swiper;
var Foundation;

function isScrolledIntoView(el) {
	  var rect = el.getBoundingClientRect();
	  return (rect.top >= 0) && (rect.bottom <= window.innerHeight);
 }

function animateFp() {
	// var gif = jQuery('.gif-wrapper').find(".gif");
	// 
	// console.log(gif);
	// 		
	// gif.empty();
	// 
	jQuery('.gif-wrapper').each(function(index, el) {
		
	  if(isScrolledIntoView(el) && !jQuery(this).hasClass("first-view") ) {
		  
		var gif = jQuery(this).find(".gif");
		
		gif.remove();
				  
		jQuery(this).addClass("first-view");
		
		jQuery(this).find(".bottom-image").css({
			opacity: 0,
			//transition: 'all 0.25s ease-in-out'
		});
		
		jQuery(this).find(".kt-inside-inner-col").prepend(gif);
		
		setTimeout(function() {
			jQuery(".gif-wrapper").find(".bottom-image").css({
				opacity: 1,
				transition: 'all 0.25s ease-in-out'
			});
		}, 3000);
		
	  }
	  
	});
	
	// jQuery("#fp-content .animate-on-scroll").each(function(index, el) {
	// 	
	// 	if(isScrolledIntoView(el) && !jQuery(this).hasClass("first-view") ) {
	// 		
	// 		jQuery(this).addClass("first-view");
	// 		
	// 		
	// 	}
	// 	
	// })
	
	
	
}

function concernsImageMapping(){
	// Get the modal
	var modal = document.getElementById("myModal");
	
	// Get the button that opens the modal
	var btn = document.getElementById("temple-area");
	
	// Get the <span> element that closes the modal
	var span = document.getElementsByClassName("close")[0];
	// When the user clicks on <span> (x), close the modal
	span.onclick = function() {
	  modal.style.display = "none";
	}
	// When the user clicks anywhere outside of the modal, close it
	window.onclick = function(event) {
	  if (event.target == modal) {
		modal.style.display = "none";
	  }
	}
	
	// When the user clicks on the button, open the modal
	
	var map = document.querySelector('map');
	
	map.addEventListener("click", eventHandler, false);
	
	function eventHandler(e) {
	  e.preventDefault();
	  if (e.target != e.currentTarget) {
		var clicked = e.target.coords;
	    //jQuery(this).attr('data-open', 'exampleModal1');
		
		  modal.style.display = "block";

		console.log('click event triggered at ' + clicked);
	  }
	  e.stopPropagation();
	}
	
}
// used to console log screen breakpoint changes while developing themes
function initDebugin() {
	var currentBreakpoint = Foundation.MediaQuery.current;
	//console.log('the current breakpoint is ' + currentBreakpoint);
	jQuery(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
		  // newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint
	});
	
	jQuery(document).on('show.zf.dropdownMenu', function(ev, $el) {
   	 	console.log('dropdown has been opened');
	});
}

function initJsGlobal() {
	// Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});

	jQuery(".is-style-play-video").find("a").attr("data-fancybox", "");
	
	jQuery(".fancybox-wrapper").find("a").attr("data-fancybox", "");
	
	jQuery(".fancybox-sibling").each(function() {
		var fancyboxContent = jQuery(this).parent().find(".fancybox-sibling-content");
		jQuery(this).children("a").append(fancyboxContent);
	})
			
	// This fixes the flash bug on the navigation
	jQuery('.lower-bar').removeClass('wait-js');
	jQuery('.sticky-wrapper-navigation').removeClass('wait-js');	
	
	
	jQuery('<div class="svg-quote"><svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47"><text id="_" data-name="“" transform="translate(0 108)" font-size="140" font-family="Helvetica"><tspan x="0" y="0">“</tspan></text></svg></div>').prependTo('blockquote');
	
	jQuery(".is-style-drop-caps").html((i, h) => `<span>${h.slice(0,1)}</span>${h.slice(1)}`);
	
	jQuery(".home-link").wrapAll("<a href='/' class='home-link-wrapper'></a>");	
	
	jQuery(document).scroll(function() {
		if (jQuery(document).scrollTop() >= 20) {
			jQuery("body").addClass("scrolled");  
		}
		else {
			jQuery("body").removeClass("scrolled");  
		}
	});
	
	var userAgent = navigator.userAgent.toLowerCase(); 
	if (userAgent .indexOf('safari')!=-1){ 
	   if(userAgent .indexOf('chrome')  > -1){
		 //browser is chrome
		 jQuery("body").addClass("is-chrome");
	   }else if((userAgent .indexOf('opera')  > -1)||(userAgent .indexOf('opr')  > -1)){
		 //browser is opera 
		 jQuery("body").addClass("is-opera");
	   }else{
		//browser is safari, add css
		jQuery("body").addClass("is-safari");
	   }
	}
}

function initFpStyles() {
	jQuery(".fp-numbered-section").each(function() {
		jQuery(this).find(".kt-row-column-wrap > .wp-block-kadence-column.text-section .wp-block-group p, .kt-row-column-wrap > .wp-block-kadence-column.text-section .wp-block-group div").wrapAll("<div class='expandContent'></div>");
		jQuery(this).find(".kt-row-column-wrap > .wp-block-kadence-column.video-section").addClass("expandContent");
		jQuery(this).find(".kt-row-column-wrap > .wp-block-kadence-column.text-section > .kt-inside-inner-col").prepend("<button class='contentExpander'>Show More</button>");
		jQuery(this).find(".contentExpander").on("click", function() {
			jQuery(this).closest(".fp-numbered-section").find(".expandContent").toggleClass("open");
		})
		
	})
	
	jQuery(".slider-small-tablet").each(function() {
		jQuery(this).addClass("swiper");
		jQuery(this).children(".kt-row-column-wrap").addClass("swiper-wrapper");
		jQuery(this).find(".wp-block-kadence-column").addClass("swiper-slide");
		jQuery(this).append("<div class='swiper-scrollbar'></div>");
	})
	
	const swiper = new Swiper('.slider-small-tablet.swiper', {
	  speed: 300,
	  spaceBetween: 20,
	  scrollbar: {
		  el: '.slider-small-tablet .swiper-scrollbar',
		  draggable: true,
	  },
	  breakpoints: {
		  0: {
			  slidesPerView: 1 
		  },
		  641: {
			slidesPerView: 1.5  
		  }
	  }
	});
	
	jQuery(".doctor-slider").each(function() {
		jQuery(this).addClass("swiper");
		jQuery(this).children(".kt-row-column-wrap").addClass("swiper-wrapper");
		jQuery(this).find(".wp-block-kadence-column").addClass("swiper-slide");
		jQuery(this).append("<div class='swiper-navigation'></div>")
		jQuery(this).find(".swiper-navigation").append("<div class='swiper-button-prev'></div>");
		jQuery(this).find(".swiper-navigation").append("<div class='swiper-pagination'></div>");
		jQuery(this).find(".swiper-navigation").append("<div class='swiper-button-next'></div>");
	})
	
	const doctorSwiper = new Swiper('.doctor-slider', {
	  speed: 300,
	  spaceBetween: 10,
	  //loop: true,
	  initialSlide: 1,
	  centeredSlides: true,
	  slidesPerView: 1, 
	  pagination: {
		  el: '.doctor-slider .swiper-pagination',
		  type: 'bullets'
	  },
	  navigation: {
		  nextEl: '.swiper-button-next',
		  prevEl: '.swiper-button-prev',
	  },
	  breakpoints: {
		  1025: {
			  spaceBetween: 20,
			  //slidesPerView: 2.5
		  }
	  }
	});
	
	
	jQuery(".fp-education-slider").each(function() {
		jQuery(this).find(".fp-education-inner").addClass("swiper");
		jQuery(this).find(".fp-education-inner > .kt-inside-inner-col").addClass("swiper-wrapper");
		jQuery(this).find(".fp-education-inner > .kt-inside-inner-col > .kb-row-layout-wrap").addClass("swiper-slide");
		jQuery(this).find(".fp-education-inner").append("<div class='swiper-navigation'></div>")
		jQuery(this).find(".swiper-navigation").append("<div class='swiper-button-prev'></div>");
		jQuery(this).find(".swiper-navigation").append("<div class='swiper-pagination'></div>");
		jQuery(this).find(".swiper-navigation").append("<div class='swiper-button-next'></div>");
	})
	
	const educationSwiper = new Swiper('.fp-education-slider .fp-education-inner', {
	  speed: 300,
	  spaceBetween: 10,
	  loop: true,
	  centeredSlides: true,
	  slidesPerView: 1, 
	  pagination: {
		  el: '.fp-education-slider .swiper-pagination',
		  type: 'bullets'
	  },
	  navigation: {
		  nextEl: '.swiper-button-next',
		  prevEl: '.swiper-button-prev',
	  },
	  effect: 'fade',
		fadeEffect: {
		  crossFade: true
		},
	  breakpoints: {
		  0: {
			allowTouchMove: false
		  },
		  769: {
			allowTouchMove: true
		  },
		  1025: {
			  spaceBetween: 20,
			  //slidesPerView: 2.5
		  }
	  }
	});
	
}

var currentTransition = "",
currentPosition = "";

function initOffCanvasSwitcher() {

	jQuery(window).on("load",function(e){
		var newSize = Foundation.MediaQuery.current;
		
		setOffcanvasPosition(newSize);	
	
	});	
		
	jQuery(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
		// newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint		 
		setOffcanvasPosition(newSize);		  
	});
	
	function setOffcanvasPosition(newSize) {		
		var mobileOffcanvas = jQuery('#off-canvas').data("mobile").split(" "),
		tabletOffcanvas = jQuery('#off-canvas').data("tablet").split(" "),
		offCanvas = jQuery('#off-canvas');
				
		if(newSize == 'xmedium' || newSize == 'medium'){		
			offCanvas.removeClass("position-"+mobileOffcanvas[0]).addClass("position-"+tabletOffcanvas[0]);
			offCanvas.removeClass("is-transition-"+mobileOffcanvas[1]).attr("data-transition", tabletOffcanvas[1]).addClass("is-transition-"+tabletOffcanvas[1]);			
			currentPosition = tabletOffcanvas[0];	
			currentTransition = tabletOffcanvas[1];					
		} 
		
		if(newSize == 'smedium' || newSize == 'small' || newSize == 'xsmall'){		
			offCanvas.removeClass("position-"+tabletOffcanvas[0]).addClass("position-"+mobileOffcanvas[0]);	
			offCanvas.removeClass("is-transition-"+tabletOffcanvas[1]).attr("data-transition", mobileOffcanvas[1]).addClass("is-transition-"+mobileOffcanvas[1]);			
			currentPosition	 = mobileOffcanvas[0];
			currentTransition = mobileOffcanvas[1];					
		}  
	}
		
	// ADDS class name to the body tag to disable scrolling when the offcanvas menu is open and adding transition and positioning class names
	jQuery('#off-canvas').on('opened.zf.offCanvas', function () {
        jQuery("body").addClass('offCanvasOpen');
		var navFixedState = Foundation.MediaQuery.is('xmedium down'),		
		hasIsAnchored = jQuery('.sticky-wrapper-navigation').hasClass('is-anchored');
		
        if(navFixedState && hasIsAnchored ){ jQuery('.sticky-wrapper-navigation').removeClass('is-anchored').addClass('is-stuck');}    
	
		jQuery(".hamburger").addClass("is-active");             
        jQuery('.off-canvas-content').removeClass('is-opened is-open-left has-position-left has-transition-push has-transition-overlap').addClass('is-opened is-open-'+currentPosition+' has-position-'+currentPosition+' has-transition-'+currentTransition);   		
    });
   
    jQuery('#off-canvas').on('close.zf.offCanvas', function () {
	    // Look for .hamburger
		jQuery(".hamburger").removeClass("is-active");
        jQuery("body").removeClass('offCanvasOpen');
        jQuery('.off-canvas-content').removeClass('is-opened is-open-'+currentPosition+' has-position-'+currentPosition);  
    });
}

function initGalleries(){
	jQuery('.imgGalItem').click(function(event) {
			
		var selector = jQuery(this),
			link = selector.attr('data-link'),
			rel  = selector.attr('rel'),
			height = selector.attr('height'),
			width = selector.attr('width'),
			counts = selector.attr('data-groups'),			
			newContent = '<a href="'+link+'" rel="galSlideCell'+rel+'" data-fancybox="group'+counts+'" data-caption="*Individual Results May Vary"><img src="'+link+'" height="+height+" width="+width+"></a>',
			containerHeight = jQuery(".galSlideCell"+rel+" .main-gal-img a").height();		
		
		jQuery(".photoGallery"+rel).removeClass("is-active");
		selector.addClass("is-active");		
			
		jQuery(".galSlideCell"+rel+" .main-gal-img").stop().css({'opacity':'0' , 'height':containerHeight+'px'}).html(function () {				
		 		return newContent;
		    }).animate({
		        opacity: 1
		    },500);	
		    
		setTimeout(function(){jQuery(".galSlideCell"+rel+" .main-gal-img").css('height','auto'); }, 500); 		
		event.preventDefault();	
		return false;
	});	

	jQuery('select#mobile-sorting').on('change', function() {
	  window.location.replace(this.value);
	});
	
}

function initReplaceFancybox(){
	var fancyboxLink = jQuery('.fancy-box a');
	var fancyboxLink2 =  jQuery('.fancybox a');
	if(fancyboxLink){
		
		fancyboxLink.attr('data-fancybox', '');
	}
	if(fancyboxLink2){
			
		fancyboxLink2.attr('data-fancybox', '');
	}

}


function initNavigation() {
	jQuery('.sticky-wrapper-navigation').on('sticky.zf.stuckto:top', function(){
	  	jQuery(this).addClass('shrink');
	}).on('sticky.zf.unstuckfrom:top', function(){
	  	jQuery(this).removeClass('shrink');
	})	
}

function clickToExpand(){	
	var expandTar = jQuery('.is-style-click-to-expand'),
		ehmax = '',
		expandTarBtn = '<a class="expandBtn"><span class="showMe">Expand</span><span class="hideMe">Collapse</span> <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M246.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 109.3 361.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160zm160 352l-160-160c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 301.3 361.4 438.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3z"/></svg></a>';
	
	expandTar.wrapInner('<div class="expandrContent"></div>');
	expandTar.append(expandTarBtn);

	jQuery('.expandrContent').each( function(){
		ehmax = jQuery(this).parent().data('eh-max');
		jQuery(this).find('.expandrContent').css('max-height', ehmax);		
		jQuery(this).css('max-height', ehmax );
	});
	
	jQuery('.expandBtn').on('click', function(){
		jQuery(this).find('.rotateMe').toggleClass('none down');
		jQuery(this).find('span').toggleClass('showMe hideMe');
		jQuery(this).parent().find('.expandrContent').toggleClass('expanded');
		jQuery(this).toggleClass('expanded');	
	});
		
}




var navigator;
var DocumentTouch;


//	Animations v1.1, Joe Mottershaw (hellojd)
//	https://github.com/hellojd/animations / https://github.com/Sananes/animations

//	==================================================

//	Visible, Sam Sehnert, samatdf, TeamDF
//	https://github.com/teamdf/jquery-visible/
//	==================================================

	(function($){
		
		$.fn.visible = function(partial,hidden,direction) {
			var $t				= jQuery(this).eq(0),
				t				= $t.get(0),
				$w				= jQuery(window),
				viewTop			= $w.scrollTop(),
				viewBottom		= viewTop + $w.height(),
				viewLeft		= $w.scrollLeft(),
				viewRight		= viewLeft + $w.width(),
				_top			= $t.offset().top,
				_bottom			= _top + $t.height(),
				_left			= $t.offset().left,
				_right			= _left + $t.width(),
				compareTop		= partial === true ? _bottom : _top,
				compareBottom	= partial === true ? _top : _bottom,
				compareLeft		= partial === true ? _right : _left,
				compareRight	= partial === true ? _left : _right,
				clientSize		= hidden === true ? t.offsetWidth * t.offsetHeight : true;
				direction		= (direction) ? direction : 'both';

			if(direction === 'both')
				return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop)) && ((compareRight <= viewRight) && (compareLeft >= viewLeft));
			else if(direction === 'vertical')
				return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop));
			else if(direction === 'horizontal')
				return !!clientSize && ((compareRight <= viewRight) && (compareLeft >= viewLeft));
		};
		

		$.fn.fireAnimations = function(options) {
			function animate() {
				if (jQuery(window).width() >= 0) {
					jQuery('.animate__animated').each(function(i, elem) {
							elem = jQuery(elem);
							//var	type = jQuery(this).attr('data-anim-type'),
							//delay = jQuery(this).attr('data-anim-delay');

						if (elem.visible(true)) {
							setTimeout(function() {
								elem.addClass('trigger');
							}, 100);
						} 
					});
				} else {
					jQuery('.animate__animated').each(function(i, elem) {
							elem = jQuery(elem);
						var	type = jQuery(this).attr('data-anim-type'),
							delay = jQuery(this).attr('data-anim-delay');

							setTimeout(function() {
								elem.addClass(type);
							}, delay);
					});
				}
			}

			jQuery(document).ready(function() {
				jQuery('html').removeClass('no-js').addClass('js');

				animate();
			});

			jQuery(window).scroll(function() {
				animate();

				if (jQuery(window).scrollTop() + jQuery(window).height() == jQuery(document).height()) {
					animate();
				}
			});
		};

		jQuery('.animate').fireAnimations();

	})(jQuery);
		
	var	triggerClasses = 'flash strobe shake bounce tada wave spin pullback wobble pulse pulsate heartbeat panic explode';
		
	var	classesArray = [];
		classesArray = triggerClasses.split(' ');

	var	classAmount = classesArray.length;
	var type;
	
	function randomClass() {
		var	random = Math.ceil(Math.random() * classAmount);

		type = classesArray[random];

		return type;
	}

	function triggerOnce(target, type) {
		if (type == 'random') {
			type = randomClass();
		}

		jQuery(target).removeClass('trigger infinite ' + triggerClasses).addClass('trigger').addClass(type).one('webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend', function() {
			jQuery(this).removeClass('trigger infinite ' + triggerClasses);
		});
	}

	function triggerInfinite(target, type) {
		if (type == 'random') {
			type = randomClass();
		}

		jQuery(target).removeClass('trigger infinite ' + triggerClasses).addClass('trigger infinite').addClass(type).one('webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend', function() {
			jQuery(this).removeClass('trigger infinite ' + triggerClasses);
		});
	}

	jQuery(window).resize(function() {
		jQuery('.animate').fireAnimations();
	});


function initBrowserExposure() {
	


	var uaMatch = '', prefix = '';

	if (navigator.userAgent.match(/Windows/))
	{
	  jQuery('html').addClass('windows');
	}
	else if (navigator.userAgent.match(/Mac OS X/))
	{
	  jQuery('html').addClass('macOs');
	}
	else if (navigator.userAgent.match(/X11/))
	{
	  jQuery('html').addClass('x11');
	}

	var supports = (function() {
	var d = document.documentElement,
		c = "ontouchstart" in window || navigator.msMaxTouchPoints;
	if (c) {
		d.className += " touch";
		return {
			touch: true
		}
	} else {
		d.className += " no-touch";
		return {
			touch: false
		}
	}
	})();

	// browser
	if (navigator.userAgent.match(/Chrome/))
	{
	  uaMatch = ' Chrome/';
	  prefix = 'chrome';
	}
	else if (navigator.userAgent.match(/Safari/))
	{
	  uaMatch = ' Version/';
	  prefix = 'safari';
	}
	else if (navigator.userAgent.match(/Firefox/))
	{
	  uaMatch = ' Firefox/';
	  prefix = 'firefox';
	}
	else if (navigator.userAgent.match(/MSIE/))
	{
	  uaMatch = ' MSIE ';
	  prefix = 'x-msie';
	}
	// add result preifx as browser class
	if (prefix)
	{
	  jQuery('html').addClass(prefix);	   
	}

}

// Get IE or Edge browser version
var version = detectIE();

if (version === false) {
  jQuery('html').addClass('not-ie-edge');
} else if (version >= 12) {
   jQuery('html').addClass('edge edge-'+ version);
} else {
   jQuery('html').addClass('ie ie-'+ version);
}

// add details to debug result
//document.getElementById('details').innerHTML = window.navigator.userAgent;

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
 
function detectIE() {
  var ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
  
  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
  
  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
  
  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
	// IE 10 or older => return version number
	return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
	// IE 11 => return version number
	var rv = ua.indexOf('rv:');
	return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
	// Edge (IE 12+) => return version number
	return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}